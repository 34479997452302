import { Menu, Select, Button, Badge } from "antd";
import {
	AppstoreOutlined,
	DollarOutlined,
	UserOutlined,
	CheckSquareOutlined,
	FileDoneOutlined,
	AreaChartOutlined,
	ShoppingOutlined,
	ReadOutlined,
	CarryOutOutlined,
	TeamOutlined,
	BankOutlined,
	ExportOutlined,
	NotificationOutlined,
	ImportOutlined,
	BuildOutlined,
	StarOutlined,
	FileTextOutlined,
	SendOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import User from "store/user";

import CertificatePurchase from "components/CertificatePurchase";

export const USER_BUSINESSES = gql`
  {
    UserBusinesses {
      name
      _id
    }
  }
`;

const MenuRoutes = ({ handleClick, keys }) => {
	const [value, setValue] = useState(null);

	const { data, loading } = useQuery(USER_BUSINESSES, {
		fetchPolicy: "no-cache",
	});

	const badges = useQuery(
		gql`
		{
			UserBadges {
				guides_pending
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const checkBusiness = async () => {
		if (!User.businessId && data?.UserBusinesses?.length) {
			await localStorage.setItem("businessId", data.UserBusinesses[0]._id);
			User.businessId = data.UserBusinesses[0]._id;
			setValue(data.UserBusinesses[0]._id);
		} else {
			setValue(User.businessId);
		}
	};

	const [getURL, { loading: loadingCert }] = useLazyQuery(
		gql`
		query GetCertificateBuyURL {
			GetCertificateBuyURL {
				url
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const getURLAction = async () => {
		try {
			let response = await getURL();
			if (response?.data?.GetCertificateBuyURL?.url) {
				window.open(response?.data?.GetCertificateBuyURL?.url);
			}
		} catch (error) {}
	};

	const onChange = async (id) => {
		await localStorage.setItem("businessId", id);
		setValue(id);
		User.setBusinessId(id);
	};

	useEffect(() => {
		checkBusiness();
	}, [data]);

	useEffect(() => {
		window.refetchBadges = badges.refetch;
	}, []);

	return (
		<>
			<div style={{ width: "100%", padding: 20 }}>
				<Select
					style={{
						width: "100%",
					}}
					placeholder={"Selecione uma empresa"}
					value={value || ""}
					onChange={(e) => {
						onChange(e);
					}}
				>
					{data?.UserBusinesses?.length
						? data.UserBusinesses.map((item) => {
								return (
									<Select.Option value={item._id}>{item.name}</Select.Option>
								);
						  })
						: null}
				</Select>
			</div>

			<Menu
				onClick={handleClick}
				style={{ width: 280 }}
				defaultSelectedKeys={keys}
				selectedKeys={keys}
				mode="inline"
				expandIcon={<span></span>}
			>
				<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
					Dashboard
				</Menu.Item>

				<Menu.Item icon={<DollarOutlined />} key={"/cash-launch"}>
					Caixa
				</Menu.Item>

				<Menu.Item icon={<ExportOutlined />} key={"/bills/expenses"}>
					Contas a Pagar
				</Menu.Item>
				<Menu.Item icon={<ImportOutlined />} key={"/bills/income"}>
					Contas a Receber
				</Menu.Item>

				<Menu.Item icon={<TeamOutlined />} key={"/clients"}>
					Clientes
				</Menu.Item>
				<Menu.Item icon={<BuildOutlined />} key={"/suppliers"}>
					Fornecedores
				</Menu.Item>

				<Menu.Item icon={<FileDoneOutlined />} key={"/receipts"}>
					Recibos
				</Menu.Item>
				<Menu.SubMenu
					icon={<AreaChartOutlined />}
					title="Relatórios"
					key="/reps"
				>
					<Menu.Item key={"/reports/income"}>
						Relatório de Faturamento
					</Menu.Item>
					<Menu.Item key={"/reports/expenses"}>Relatório de Despesa</Menu.Item>
				</Menu.SubMenu>

				<Menu.Item icon={<DownloadOutlined />} key={"/documents"}>
					Docs. Enviados/Recebidos
				</Menu.Item>

				<Menu.Item icon={<FileDoneOutlined />} key={"/documents/important"}>
					Documentos Importantes
				</Menu.Item>

				<Menu.Item icon={<CarryOutOutlined />} key={"/services"}>
					Serviços
				</Menu.Item>

				<Menu.Item icon={<FileTextOutlined />} key={"/guides"}>
					Guias
					<span style={{ paddingLeft: 10 }}>
						<Badge count={badges?.data?.UserBadges?.guides_pending} />
					</span>
				</Menu.Item>

				<Menu.Item icon={<CarryOutOutlined />} key={"/invoices"}>
					Notas Fiscais
				</Menu.Item>

				<Menu.Item icon={<BankOutlined />} key={"/business"}>
					Empresa
				</Menu.Item>

				<Menu.Item icon={<StarOutlined />} key={"/reviews"}>
					Avaliações
				</Menu.Item>

				<Menu.Item icon={<NotificationOutlined />} key={"/refer"}>
					Clube UAU OpenYOU
				</Menu.Item>
			</Menu>

			<CertificatePurchase />
		</>
	);
};

export default MenuRoutes;
